<template>
	<div class="di">
		<div class="my-container">
			<div class="banner-box-swiper">
				<!-- 这里一定要加 (v-if="carouselList.length > 0") 判断 否则 loop 循环就会失效 （使用静态轮播图数据不会出现这种情况）
					原因：动态获取轮播图数据，数据还没拿到，dom已经加载完成，所以loop会失效-->
				<swiper v-if="carouselList.length > 0" :options="swiperOption" ref="mySwiper">
					<swiper-slide class="swiper-slide" v-for="(item,key) in carouselList" :key="key">
						<div>
							<a @click="jumpclick(item)">
								<img :src="item.img" style="width: 100%;cursor: pointer;text-align: center;" alt="">
							</a>
						</div>
						<div class="img1">{{item.title}}</div>
					</swiper-slide>
				</swiper>
			</div>
			<div class="indicators">
				<div :class="'item ' + (carouselIndex == index ? 'active' : '')"
					v-for="(_, index) in carouselList.length" :key="index"></div>
			</div>
		</div>

		<div>
			<div class="dn"
				style="height: 40px;display: block;overflow: hidden;width: 100%;border-bottom: 1px solid #c2cfe2;">
				<a>行业资讯</a></div>
			<div class="du">
				<ul>
					<li v-for="(item,key) in dataInfor" :key="key">
						<a target="_blank" :title="item.title" @click="jumpclick(item)">{{ item.title }}</a>
					</li>
				</ul>
			</div>
		</div>

		<div style="margin-top: 15px;">
			<div class="dn"
				style="height: 40px;display: inline-block;font-size: 23px;color: #0088cc;line-height: 20px;"><a
					>行业观察</a></div>
			<div class="du2">
				<ul style="list-style: none;">
					<li v-for="(item,key) in industryObser" :key="key">
						<a target="_blank" :title="item.title" @click="jumpclick(item)">{{ item.title }}</a>
						<span style="line-height:39px;">{{item.creatTime}}</span>
					</li>
				</ul>
			</div>
		</div>
		
		<div style="margin-top: 15px;margin-bottom: 20px;">
			<div class="dn"
				style="height: 40px;display: inline-block;font-size: 23px;color: #0088cc;line-height: 20px;"><a
					>行业案例</a></div>
			<div class="du2">
				<ul style="list-style: none;">
					<li v-for="(item,key) in industryCase" :key="key">
						<a target="_blank" :title="item.title" @click="jumpclick(item)">{{ item.title }}</a>
						<span style="line-height:39px;">{{item.creatTime}}</span>
					</li>
				</ul>
			</div>
		</div>
		
	</div>
</template>
<script>
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	let vm = null
	export default {
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				carouselList: [],
				dataInfor: [],
				industryObser: [],
				industryCase: [],
				active: 0,
				carouselIndex: 0,
				// 异形轮播图配置
				swiperOption: {
					effect: 'coverflow',
					coverflowEffect: {
						rotate: 0, // slide做3d旋转时Y轴的旋转角度 (默认50).
						stretch: -30, // 每个slide之间的拉伸值 越大slide靠得越紧.
						depth: 100, // slide的位置深度 值越大z轴距离越远 看起来越小.
						modifier: 1, // depth和rotate和stretch的倍率 相当于(depth*modifier、rotate*modifier、stretch*modifier) 值越大这三个参数的效果越明显(默认1).
						slideShadows: false // 开启slide阴影 (默认 true)
					},
					// 默认选中中间一张
					centeredSlides: true,
					// 无限循环
					loop: true,
					// 自动轮播
					autoplay: {
						delay: 1500,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					slideToClickedSlide: true,
					on: {
						slideChange: function() {
							vm.carouselIndex = this.realIndex
						}
					}
				}
			}
		},
		created() {
			vm = this;
			let that = this;
			this.$post("/industry/chartindustry", {
				headline: "行业资讯",
				size: "10"
			}).then(resp => {
				that.dataInfor = resp.dataList;
				resp.dataList.forEach(item => {
					if (item.img != null && item.img.length > 0) {
						that.carouselList.push(item);
					}
				})
			});

			this.$post("/industry/chartindustry", {
				headline: "行业观察",
				size: "6"
			}).then(resp => {
				this.industryObser = resp.dataList
			});
			
			this.$post("/industry/chartindustry", {
				headline: "行业案例",
				size: "6"
			}).then(resp => {
				this.industryCase = resp.dataList
			});
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			}
		},
		methods: {
			jumpclick(data) {
				this.$router.push({
					path: "/mdetails",
					query: {
						firstdata: data
					}
				})
			},
		},
	}
</script>
<style scoped>
	.el-link {
		justify-content: left;
		left: -10px;
	}

	.my-container {
		height: 100%;
		width: 100%;
		padding-top: 12px;
		box-sizing: border-box;

		.banner-box-swiper {
			width: 100%;
			height: 180px;
			overflow: hidden;

			.swiper-container {
				height: 280px;
				width: 290px;
				overflow: visible;

				.swiper-slide {
					text-align: center;
					background: #fff;
					transition: 300ms;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					a {
						width: 100%;
						height: 100%;
						display: block;
						box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.21);
						border-radius: 10px;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							display: block;
						}
					}
				}
			}
		}

		.indicators {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 18px 0;

			.item {
				width: 18px;
				height: 3px;
				border-radius: 2px;
				margin-left: 10px;
				background: #d9d9d9;

				&.active {
					background: #ff8008;
				}
			}
		}
	}

	.img1 {
		position: absolute;
		bottom: 0;
		height: 30px;
		width: 100%;
		line-height: 30px;
		color: #fff;
		font-size: 10px;
		text-indent: 2em;
		background: url(../../assets/img/lb-bg.png) center;
	}

	.di {
		width: 95%;
		margin-top: 15px;
		margin: 0 auto;
	}

	.dn a {
		float: left;
		line-height: 50px;
		text-align: left;
		font-size: 23px;
		color: #0088cc;
	}

	.du li {
		white-space: nowrap;
		/*设置不换行*/
		overflow: hidden;
		/*设置隐藏*/
		text-overflow: ellipsis;
		padding-right: 0px !important;
		width: 100%;
		margin-top: 12px;
		font-size: 15px;
		color: #333;
		line-height: 18px;
		text-indent: 1em;
		background: url(../../assets/img/dina_18.png) no-repeat left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.dn2 {
		width: 100%;
		float: left;
		margin: 15px 0px;
	}

	.du2 {
		padding: 10px;
		height: auto !important;
		width: 100%;
		border: 1px solid #c2cfe3;
		box-sizing: border-box;
	}

	.du2 li {
		line-height: 35px;
		height: 35px;
		font-size: 14px;
		overflow: hidden;
		text-indent: 1em;
		background: url(../../assets/img/dina_18.png) no-repeat left;
		position: relative;
	}

	.du2 ul li a {
		right: 95px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #333;
		position: absolute;
		left: 0px;
	}

	.du2 ul li span {
		line-height: 35px !important;
		font-style: normal;
		display: block;
		float: right;
		font-size: 13px;
	}
</style>
